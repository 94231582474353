import { FilterConfig, FilterOptions } from '@/models/filters-config';

const filterOptions: FilterOptions = {
  defaultPanelSize: {
    width: 3,
    height: 8,
  },
};

const filters: FilterConfig[] = [
  {
    type: 'Multiselect',
    isPanel: false,
    extraFieldsFormComponent: null,
    filterComponent: import(/* webpackPrefetch: true */ './multiselect/FilterMultiselect.vue'),
  },
  {
    type: 'Map',
    isPanel: true,
    extraFieldsFormComponent: import(/* webpackPrefetch: true */ './map/ExtraFieldsFormMap.vue'),
    filterComponent: import(/* webpackPrefetch: true */ './map/FilterMap.vue'),
  },
];

export { filterOptions, filters };
