












































































































































































import { Vue, Component } from 'vue-property-decorator';
import VueGridLayout from 'vue-grid-layout';
// import { charts } from '@/components/charts/charts-config';
import FilterContainer from '@/components/filters/FilterContainer.vue';
import ChartContainer from '@/components/charts/ChartContainer.vue';
import DashboardApi from '@/services/dashboard';
import { PanelPosition, PanelPositionWithIndex, DashboardConfiguration } from '@/models/dashboard';
import { EnumRole } from '@/models/user';
import { EnumGlobalModal } from '@/models/global-modals';
import store from '@/store';

@Component({
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    FilterContainer,
    ChartContainer,
  },
})
export default class Dashboard extends Vue {
  layout: PanelPositionWithIndex[] | null = null;

  dashboardApi = new DashboardApi();

  dashboardId: number | null = null;

  dashboard: DashboardConfiguration | null = null;

  // chartsConfig: ChartConfig[] = charts;

  panelToDelete: number | null = null;

  isModalDeletePanelOpen = false;

  // todo: start with editMode if dashboard is locked by user
  isEditMode = false;

  created(): void {
    store.dispatch('setLoading', true);

    this.dashboardId = parseInt(this.$route.params.id, 10);

    // todo: should move to a config or a central file
    const rolesWithoutQueryEncryption = [EnumRole.ADMIN, EnumRole.EDITOR];
    const userRole: EnumRole | null = store.getters.getUser ? store.getters.getUser.role : null;
    const encryptedQueries = !userRole || !rolesWithoutQueryEncryption.includes(userRole);

    this.dashboardApi.findOne(this.dashboardId, encryptedQueries).then((response) => {
      if (response.success) {
        this.dashboard = response.result;
        console.log('dashboard', this.dashboard);

        this.layout = this.dashboard.panels ? this.dashboard.panels.map((x, i) => ({ ...x.position, i })) : [];

        store.dispatch('setLoading', false);
      } else {
        console.log('err', response);
      }
    });
  }

  get showSaveBtn(): boolean {
    if (this.dashboard && this.dashboard.panels && JSON.stringify(this.layout) !== JSON.stringify(this.dashboard.panels.map((x, i) => ({ ...x.position, i })))) return true;
    return false;
  }

  editDashboard(): void {
    this.dashboardApi.startEditingDashboard(this.dashboardId!).then((response) => {
      if (response.success) {
        this.isEditMode = true;
      } else { // todo: only in DASHBOARD_IS_LOCKED error message
        console.log('err', response);
        store.dispatch('setShownGlobalModal', EnumGlobalModal.DASHBOARD_LOCKED);
      }
    });
  }

  discardDashboardChanges(): void {
    this.dashboardApi.stopEditingDashboard(this.dashboardId!).then((response) => {
      if (response.success) {
        this.isEditMode = false;
      } else {
        console.log('err', response);
      }
    });
  }

  // todo
  savePanelPositions(): void {
    this.dashboard = {
      ...this.dashboard!,
      panels: this.dashboard!.panels!.map((x, i) => ({
        ...x,
        position: {
          x: this.layout![i].x,
          y: this.layout![i].y,
          w: this.layout![i].w,
          h: this.layout![i].h,
        },
      })),
    };

    this.updateDashboard();
  }

  showDeletePanelModal(id: number): void {
    this.panelToDelete = id;
    this.isModalDeletePanelOpen = true;
  }

  deletePanel(): void {
    this.dashboard = {
      ...this.dashboard!,
      panels: this.dashboard!.panels!.filter((x) => x.id !== this.panelToDelete),
    };

    this.updateDashboard();
  }

  // todo: stop edit mode after updating
  updateDashboard(): void {
    this.dashboardApi.update(this.dashboard!.id, this.dashboard!).then((response) => {
      if (response.success) {
        console.log('dashboard saved!');
      } else {
        console.log('err', response);
      }
    });
  }

  onPanelResize(i: number): void {
    (this.$refs.panel as Array<ChartContainer>)[i].onPanelResize();
  }
}
